.mutations-table-container-class {
    tr {
        padding-top: 0.2rem !important;
        padding-bottom: 0.2rem !important;
    }

    tr td {
        padding-top: 0.2rem !important;
        padding-bottom: 0.2rem !important;
    }
}

.research-papers-col {
    background: #ffffff;
    max-height: 8rem;
    overflow: scroll;

    .papers-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-flow: row;
        overflow-x: scroll;
        overflow-y: scroll;
    }
}

@media (max-width: 1500px) {
    .research-papers-col {
        min-width: 22.5rem;
        max-width: 25rem;

        .papers-list {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@media (max-width: 1050px) {
    .research-papers-col {
        min-width: 12.5rem;
        max-width: 12rem;

        .papers-list {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}
