.button-container {
    cursor: pointer;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 0.25rem;

    padding: 0.5em 1em;

    margin: auto;

    .text {
        padding-right: 0.225rem;
        font-size: 0.8rem;
        font-weight: 500;
        margin: auto;
    }

    .icon {
        display: flex;
        padding-left: 0.225rem;
        align-items: center;
    }

    &:hover {
        background-color: #e2e2e2;
        text-decoration: underline;
    }
}
