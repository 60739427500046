.sequence-output-container {
    .sequence-output-content {
        display: flex;
        flex-direction: column;
        padding-top: 2rem;
        gap: 2.5rem;

        h1 {
            font-size: 1.75rem;
            padding-bottom: 1.5rem;
        }

        .save-session {
            .save-inputs {
                display: flex;
                flex-direction: column;
                gap: 1.5rem;
            }

            .save-button-row {
                display: flex;
                height: 3.5rem;
                margin-top: 1.5rem;
                flex-basis: 100%;

                .save-button {
                    flex-grow: 0;
                    height: 3rem;
                }

                .spacer {
                    flex-grow: 1;
                }

                p {
                    padding-left: 1rem;
                    margin: auto;
                    margin-top: 1.75rem;
                }

                .hidden {
                    display: none;
                }
            }
        }

        .terms-text {
            padding-top: 1rem;
        }
    }
}
