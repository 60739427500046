.sequence-output-container {
    .sequence-output-content {
        display: flex;
        flex-direction: column;
        padding-top: 2rem;
        gap: 2.5rem;

        h1 {
            font-size: 1.75rem;
            padding-bottom: 1.5rem;
        }

        .output-method {
            .output-method-buttons {
                display: flex;
                gap: 0.5rem;
            }
        }

        .sequence-output {
            .alignment-output {
                display: flex;
                flex-basis: 100%;
                justify-content: space-between;

                .buttons-col {
                    flex-grow: 0;
                    display: flex;
                    flex-direction: column;
                    height: 10rem;
                    gap: 1rem;
                    padding-bottom: 3.5rem;
                }

                .spacer-col {
                    flex-grow: 1;
                }
            }

            .tabular-output {
                display: flex;
                flex-basis: 100%;
                justify-content: space-between;

                .buttons-col {
                    flex-grow: 0;
                    display: flex;
                    flex-direction: column;
                    height: 10rem;
                    gap: 1rem;
                    padding-bottom: 3.5rem;
                }

                .spacer-col {
                    flex-grow: 1;
                }
            }
        }
    }
}
