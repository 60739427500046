// code inspired from https://codepen.io/BeanBaag/pen/mMJqma
.tabular-screen {
    width: 100vw;
    height: calc(100vh - 7.5rem);

    visibility: hidden;
    opacity: 0;
    z-index: 10;
    position: absolute;
    bottom: 0%;
    left: 0%;

    background: #ffffff;

    .content-container {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        margin: 0 auto;
        padding-top: 2.5rem;
        width: 86vw;

        .buttons-row {
            display: flex;
            flex-basis: 100%;

            height: 3rem;

            .spacer {
                flex-grow: 1;
            }
        }

        .table-row {
            width: 86vw;
            //max-height: calc(calc(100vh - 7.5rem) - 12.5rem);
        }
    }

    &.open {
        visibility: visible;
        opacity: 1;
    }
}
