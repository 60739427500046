// code inspired from https://codepen.io/BeanBaag/pen/mMJqma
.login-screen {
    width: 30rem;
    height: 25rem;

    visibility: hidden;
    opacity: 0;
    z-index: 20;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    //box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.4);
    outline: #000000 solid 0.25rem;

    background-color: #ffffff;

    padding: 1.75rem;
    border-radius: 0.25rem;

    .content-container-login-screen {
        text-align: center;

        .close-button-container {
            display: flex;
            width: 100%;
            flex-direction: row-reverse;

            .close-icon {
                color: #000000;
                &:hover {
                    cursor: pointer;
                }
            }
        }

        .nav-logo {
            width: 15rem;
        }

        h1 {
            font-size: 2.25rem;
            text-decoration: underline;
            padding-bottom: 4rem;
        }

        .login-buttons {
            padding: 3rem 3rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }

        p {
            font-size: 0.8rem;
        }
    }

    &.open {
        visibility: visible;
        opacity: 1;
    }
}
