.text-box-container {
    width: 100%;
    height: 100%;
    border-radius: 0.25rem;

    background: #ffffff;

    .text {
        color: #000000;
        border: solid 0.15rem #000000;
        border-radius: 0.25rem;
        padding: 0.75rem 1rem;
        font-size: 1.25rem;
        font-family: 'roboto';
        resize: none;
        width: 100%;
        height: 100%;
    }
}
