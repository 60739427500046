// code inspired from https://codepen.io/BeanBaag/pen/mMJqma
.sessions-screen {
    width: 40rem;
    height: 47.5rem;

    visibility: hidden;
    opacity: 0;
    z-index: 20;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    //box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.4);
    outline: #000000 solid 0.25rem;

    background-color: #ffffff;

    padding: 1.75rem;
    border-radius: 0.25rem;

    .content-container-sessions-screen {
        text-align: center;

        .close-button-container {
            display: flex;
            flex-direction: row-reverse;

            .close-icon {
                color: #000000;
                &:hover {
                    cursor: pointer;
                }
            }
        }

        h1 {
            font-size: 2.5rem;
            margin-top: 0.75rem;
            margin-bottom: 2.5rem;
        }

        h2 {
            font-size: 1.25rem;
            margin-top: 0.75rem;
            padding-bottom: 0.25rem;
            padding-left: 0.25rem;
            margin-bottom: 2.25rem;
            border-bottom: 0.15rem solid #000000;
            text-align: left;
            color: #000000;
        }

        .session-save-row {
            padding-right: 1rem;
            padding-left: 1rem;
            padding-bottom: 1.75rem;
            display: flex;
            justify-content: space-between;

            .session-name {
                text-align: left;
                width: 13ch;
                font-weight: 500;
            }

            input {
                width: 13ch;
                padding: 0.175rem;
                margin: 0;
            }

            .session-archetype {
                width: 10ch;
            }

            .row-text {
                font-size: 1.05rem;
                display: flex;
                align-items: center;
                padding: 0 0.8rem;
            }

            .button-col {
                width: 7ch;
            }

            .session-delete-button-container {
                visibility: hidden;
            }
        }

        .session-row {
            padding-right: 1rem;
            padding-left: 1rem;
            padding-bottom: 1rem;
            display: flex;
            justify-content: space-between;
            flex-basis: 100%;

            .session-name {
                text-align: left;
                width: 13ch;
                font-weight: 500;
            }

            .row-text {
                font-size: 1.05rem;
                display: flex;
                align-items: center;
                padding: 0 0.8rem;
            }

            .button-col {
                width: 7ch;
            }

            .session-archetype {
                width: 10ch;
            }
        }
    }

    .session-restore-button-container {
        padding-left: 0.1rem;
    }

    .session-delete-button-container {
        padding-right: 0.1rem;
    }

    &.open {
        visibility: visible;
        opacity: 1;
    }
}
