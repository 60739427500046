.sequence-input-container {
    min-height: 40rem;
    .sequence-input-content {
        display: flex;
        flex-direction: column;
        padding-top: 2rem;
        gap: 2.5rem;

        h1 {
            font-size: 1.75rem;
            padding-bottom: 1.5rem;
        }

        .input-method {
            .input-method-buttons {
                display: flex;
                gap: 0.5rem;
            }
        }

        .sequence-input {
            .upload-input {
                display: flex;
                flex-direction: column;
                flex-basis: 100%;
                gap: 0.5rem;
                height: 15rem;

                .upload-row {
                    height: 1.5rem;
                    padding-bottom: 1rem;

                    .custom-file-upload {
                        border: 0.15rem solid #afafaf;
                        color: #afafaf;
                        padding: 0.4rem 1rem;
                        border-radius: 0.25rem;
                        font-size: 0.85rem;
                        font-weight: 500;
                        cursor: pointer;

                        &:hover {
                            border: 0.15rem solid #000000;
                            color: #000000;
                        }
                    }

                    input[type='file'] {
                        display: none;
                    }
                }

                .input-row {
                    min-height: 27.5rem;
                }

                .metadata-text {
                    position: relative;
                    color: blue;
                    text-decoration: 'underline';

                    .hover-text {
                        text-align: left;
                        visibility: hidden;
                        width: 100%;
                        background-color: black;
                        color: #fff;

                        padding: 1rem 1rem;
                        border-radius: 0.15rem;

                        /* Position the tooltip text - see examples below! */
                        position: absolute;
                        z-index: 1;
                        bottom: 3rem;
                        //right: 105%;
                    }

                    &:hover {
                        cursor: pointer;

                        .hover-text {
                            visibility: visible;
                        }
                    }
                }

                // .button-row {
                //     max-height: 2.5rem;
                //     display: flex;
                //     flex-basis: 100%;

                //     .edit-metadata {
                //         flex-grow: 0;
                //     }

                //     .spacer {
                //         flex-grow: 1;
                //     }
                // }
            }
        }
    }
}
