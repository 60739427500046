.archetype-table-container-class {
    tr {
        padding-top: 0.2rem !important;
        padding-bottom: 0.2rem !important;
    }

    tr td {
        padding-top: 0.2rem !important;
        padding-bottom: 0.2rem !important;
    }
}

.archetype-fasta {
    //max-width: 10rem;
    //min-height: 10rem;
    overflow-x: scroll;
    overflow-y: scroll;
    white-space: nowrap;
    p {
        max-width: 5ch;
    }
    font-size: 0.8rem;
}
