.info-container {
    background-color: #ffffff;
    display: flex;
    padding: 1.2rem 1.2rem;
    outline: #afafaf solid 0.125rem;
    border-radius: 0.1rem;
    flex-basis: 100%;
    justify-content: space-between;
    height: 18.75vh;

    h2 {
        font-size: 1.15rem;
        padding-bottom: 0.5rem;
    }

    .sequence-label-span {
        width: 10ch;
    }

    .info-row {
        display: flex;
        font-size: 0.85rem;
        span {
            font-weight: 500;
        }
        .data {
            margin-left: 0.75rem;

            span {
                text-decoration: none;
                font-weight: normal;
            }
        }

        .sequence {
            display: flex;
            text-align: center;
            font-family: 'Roboto Mono', sans-serif;
            font-weight: normal;
            font-size: 0.9rem;
            white-space: nowrap;
            margin-top: 0;
            padding-top: 0;
        }

        .sequence-character-container {
            position: relative;
            width: 2ch;
            text-align: center;
            display: flex;
            flex-direction: column;
            margin: 0.165rem;
            margin-top: 0;
            padding-top: 0;
            margin-top: -0.25rem;

            .position-number-archetype {
                font-size: 0.5rem;
                font-weight: 500;
                color: #5856e6;
            }

            .position-number-sequence {
                font-size: 0.5rem;
                font-weight: 500;
                color: #eba31e;
            }

            .character-container {
                margin: 0.15rem;
            }
        }

        .hidden {
            visibility: hidden;
        }

        .unknown-mutation {
            .character-container {
                border-radius: 0.15rem;
                background-color: rgb(255, 86, 86);
            }

            &:hover {
                cursor: pointer;
            }
        }

        .known-mutation {
            .character-container {
                border-radius: 0.15rem;
                background-color: rgb(255, 208, 80);
            }

            &:hover {
                cursor: pointer;
            }
        }
    }

    .info-col {
        display: flex;
        flex-direction: column;
        gap: 0.7rem;
    }

    .main-col-container {
        display: flex;
        gap: 8rem;
    }

    .papers-col-container {
        display: flex;
        gap: 8rem;

        .research-papers-col {
            min-width: 22.5rem;
            max-width: 25rem;
            overflow: hidden;

            .papers-list {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-auto-flow: row;
                overflow-x: hidden;
                overflow-y: scroll;
            }
        }
    }
}

@media only screen and (max-width: 1300px) {
    .info-container {
        padding: 1.2rem 1.2rem;

        height: 23.75vh;

        h2 {
            font-size: 1rem;
            padding-bottom: 0.5rem;
        }

        .info-row {
            font-size: 0.85rem;

            .data {
                margin-left: 0.75rem;
            }

            .sequence {
                display: flex;
                text-align: center;
                font-family: 'Roboto Mono', sans-serif;
                font-weight: normal;
                font-size: 0.9rem;
                white-space: nowrap;
                margin-top: 0;
                padding-top: 0;
            }

            .sequence-character-container {
                position: relative;
                width: 2ch;
                text-align: center;
                display: flex;
                flex-direction: column;
                margin: 0.165rem;
                margin-top: 0;
                padding-top: 0;
                margin-top: -0.25rem;

                .position-number-archetype {
                    font-size: 0.5rem;
                    font-weight: 500;
                    color: #5856e6;
                }

                .position-number-sequence {
                    font-size: 0.5rem;
                    font-weight: 500;
                    color: #eba31e;
                }

                .character-container {
                    margin: 0.15rem;
                }
            }
        }

        .info-col {
            gap: 0.7rem;
        }

        .main-col-container {
            gap: 4rem;
        }

        .papers-col-container {
            gap: 8rem;

            .research-papers-col {
                min-width: 12.5rem;
                max-width: 12rem;

                .papers-list {
                    grid-template-columns: repeat(1, 1fr);
                    grid-auto-flow: row;
                }
            }
        }
    }
}
