// code inspired from https://codepen.io/BeanBaag/pen/mMJqma
.contact-screen-container {
    width: 35rem;
    height: 42rem;

    visibility: hidden;
    opacity: 0;
    z-index: 20;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    //box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.4);
    outline: #000000 solid 0.25rem;

    background-color: #ffffff;

    padding: 1.75rem;
    border-radius: 0.25rem;

    .content-container-contact-screen {
        text-align: center;

        .close-button-container {
            display: flex;
            width: 100%;
            flex-direction: row-reverse;

            .close-icon {
                color: #000000;
                &:hover {
                    cursor: pointer;
                }
            }
        }

        h1 {
            font-size: 2.25rem;
            text-decoration: underline;
            padding-bottom: 4rem;
        }

        .text {
            .radio-buttons {
                text-align: left;
                padding-left: 1rem;
                padding-bottom: 2rem;
            }

            .text-box {
                height: 14rem;
            }

            .submit-button-row {
                padding-top: 1.5rem;
                display: flex;
                float: right;
                width: 10rem;

                .spacer {
                    width: 10rem;
                }
            }
        }
    }

    &.open {
        visibility: visible;
        opacity: 1;
    }
}
