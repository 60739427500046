@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;500;700&display=swap');

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
}

::-webkit-scrollbar {
    width: 1rem;
    height: 1rem;
}
::-webkit-scrollbar-button {
    display: none;
}
::-webkit-scrollbar-track {
    background: #ffffff;
}
::-webkit-scrollbar-thumb {
    background: rgb(190, 190, 190);
    border-left: 0.2rem #ffffff solid;
    border-right: 0.2rem #ffffff solid;
    border-top: 0.2rem #ffffff solid;
    border-bottom: 0.2rem #ffffff solid;
}

::-webkit-scrollbar-thumb:hover {
    background: #9b9b9b;
}

.main-container {
    background: #ffffff;
    font-family: 'roboto';
    margin: 2vw auto;
    width: 86vw;

    .content-container {
        display: flex;
        flex-basis: 100%;
        justify-content: space-between;
        gap: 5vw;

        padding-top: 7.5vh;

        .progress-column {
            flex-grow: 0;
        }

        .interface-column {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            .information-column {
                flex-grow: 1;
                height: 100%;
            }

            .steps-column {
                flex-grow: 1;
                .step-row {
                    //position: absolute;
                    //bottom: 3rem;
                    //right: 7vw;
                    display: flex;
                    flex-basis: 100%;
                    justify-content: space-between;
                    margin-top: 1.5rem;
                    gap: 1rem;

                    .spacer {
                        flex-grow: 1;
                    }

                    .step-buttons {
                        height: 3rem;
                        display: flex;
                        gap: 1rem;
                    }
                }
            }
        }
    }
}
