.paper-button-container {
    max-width: 10rem;
    display: flex;
    justify-content: space-around;

    color: black;
    text-decoration: none;

    background: #e1e1e1;
    padding: 0.35rem 1rem;
    margin: 0.3rem 0;
    margin-right: 0.8rem;
    border-radius: 0.25rem;
    font-size: 0.6rem;

    .link-icon {
        width: 1rem;
        height: 1rem;
        margin-left: 0.5rem;
    }

    &:hover {
        background: #c9c9c9;
        cursor: pointer;
    }

    h3 {
        width: 7rem;
        min-width: 7rem;
        max-width: 7rem;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
