// code inspired from https://codepen.io/BeanBaag/pen/mMJqma
.alignment-screen {
    width: 100vw;
    height: calc(100vh - 7.5rem);
    //height: auto;

    visibility: hidden;
    opacity: 0;
    z-index: 10;
    position: absolute;
    bottom: 0%;
    left: 0%;

    background: #ffffff;

    .content-container {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        margin: 0 auto;
        padding-top: 2.5rem;
        width: 86vw;

        .buttons-row {
            display: flex;
            flex-basis: 100%;

            height: 3rem;

            .spacer {
                flex-grow: 1;
            }
        }

        .alignment-row {
            background: #ffffff;
            display: flex;
            justify-content: space-between;

            .alignment-col {
                width: 71vw;
                background: #ffffff;

                .legend-row {
                    display: flex;
                    padding-right: 1.75rem;
                    gap: 1.75rem;
                    justify-content: flex-end;
                    color: #9e9e9e;
                    font-size: 0.9rem;
                    .archetype-index {
                        color: #5856e6;
                    }

                    .sequence-index {
                        color: #eba31e;
                    }
                }
            }

            .settings-col {
                width: 15vw;
                overflow: hidden;
            }
        }
    }

    &.open {
        visibility: visible;
        opacity: 1;
    }
}

@media only screen and (max-width: 1300px) {
    .alignment-screen {
        width: 100vw;
        height: calc(100vh - 7.5rem);

        .content-container {
            gap: 2rem;
            margin: 0 auto;
            padding-top: 2.5rem;
            width: 86vw;

            .alignment-row {
                .alignment-col {
                    width: 61vw;

                    .legend-row {
                        padding-right: 0rem;
                        gap: 1.75rem;

                        font-size: 0.9rem;
                    }
                }

                .settings-col {
                    width: 22vw;
                }
            }
        }
    }
}
