.sequence-selector-container {
    width: 15rem;
    height: 15rem;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    font-size: 1.1rem;

    .selected-sequence {
        display: flex;
        height: 3rem;
        border-radius: 0.25rem;
        border: solid 0.15rem #000000;

        .sequence-name {
            margin: auto 0.75rem;
        }
    }

    .all-sequences-container {
        height: 11.75rem;
        border-radius: 0.25rem;
        border: solid 0.15rem #afafaf;

        .headers-row {
            height: 1.75rem;
            display: flex;
            flex-basis: 100%;
            justify-content: space-between;
            color: #747474;
            font-weight: 500;

            .sequence-stats-header {
                padding: 0.6rem 0.75rem;
                padding-bottom: 0rem;
                font-size: 0.85rem;
                padding-right: 1.75rem;
            }

            .sequence-name-header {
                padding: 0.6rem 0.75rem;
                padding-bottom: 0rem;
                font-size: 0.85rem;
            }
        }
    }

    .all-sequences {
        display: flex;

        flex-direction: column;
        max-height: calc(100% - 2.15rem);
        padding: 0.6rem 0;
        overflow-y: scroll;

        &:hover {
            cursor: pointer;
        }

        .sequence-row-container {
            display: flex;

            flex-basis: 100%;
            justify-content: space-between;

            .sequence-stats {
                padding: 0.6rem 0.75rem;
            }

            .sequence-name {
                padding: 0.6rem 0.75rem;
            }
        }
    }

    .sequence-name.selected {
        font-weight: bold;
        text-decoration: underline;
    }

    .sequence-stats.selected {
        font-weight: bold;
    }
}
