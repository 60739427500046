.archetype-input-container {
    .archetype-input-content {
        display: flex;
        flex-direction: column;
        padding-top: 2rem;
        gap: 2.5rem;

        h1 {
            font-size: 1.75rem;
            padding-bottom: 1.5rem;
        }

        .input-method {
            .input-method-buttons {
                display: flex;
                gap: 0.5rem;
            }
        }

        .archetype-input {
            .reference-input {
                display: flex;
                flex-basis: 100%;
                gap: 0.5rem;

                .selector-col {
                    flex-grow: 0;
                }

                .input-col {
                    flex-grow: 1;
                }
            }

            .custom-input {
                .input-col {
                    height: 15rem;
                }
            }

            .automatic-input {
                .search-screen {
                    display: flex;
                    flex-basis: 100%;
                    gap: 0.5rem;

                    .input-col {
                        height: 15rem;
                        flex-grow: 1;
                    }

                    .search-button {
                        height: 3rem;
                        //margin-top: auto;
                    }
                }

                .output-screen {
                    display: flex;
                    flex-basis: 100%;
                    gap: 0.5rem;

                    .back-button {
                        height: 3rem;
                        //margin-top: auto;
                    }

                    .output-col {
                        display: flex;
                        flex-basis: 100%;
                        gap: 0.5rem;

                        .selector-col {
                            flex-grow: 0;
                        }

                        .input-col {
                            flex-grow: 1;
                        }
                    }
                }
            }
        }
    }
}
