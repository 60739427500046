.container
{
    .step
    {
        display: flex;

        .visual-column
        {
            display: flex;
            position: relative;
            flex-direction: column;
            margin-right: 0.75rem;

            .triangle
            {
                position: absolute;
                left: -1.5rem;
                top: 0.1rem;
            }

            .triangle.hidden
            {
                display: none;
            }

            .circle
            {
                width: 1.25rem;

                &:hover
                {
                    cursor: pointer;
                }
            }

            .line
            {
                height: 8.5rem;
            }
        }

        .text-column
        {
            h2
            {
                margin-top: -0.2rem;
                font-size: 1.5rem;

                &:hover
                {
                    cursor: pointer;
                }
            }

            h3
            {
                color: #AFAFAF;
                font-size: 1rem;
                padding-top: 0.3rem;

                &:hover
                {
                    cursor: pointer;
                }
            }
        }
    }
}