.information-button-container {
  width: 12.5rem;
  height: 12.5rem;

  cursor: pointer;

  display: flex;
  flex-direction: column;

  border-radius: 0.25rem;
  border: solid 0.15rem #afafaf;



  .title {
    font-size: 1.25rem;
    font-weight: bold;
    margin: 0 auto;
    padding: 1rem 0;
  }

  .description {
    font-size: 1rem;
    font-weight: 500;
    margin: 0 auto;
    text-align: center;
    padding: 0 1.25rem;
  }

  &:hover {
    border: solid 0.15rem #000000;
  }
}

.information-button-container.selected
{
  border: solid 0.2rem #000000;

  .title
  {
    text-decoration: underline;
  }
}
