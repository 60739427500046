.input-box-container {
    width: 100%;
    height: 100%;
    border-radius: 0.25rem;

    background: #e0e0e0;

    .text {
        color: #000000;
        border: solid 0.15rem #000000;
        border-radius: 0.25rem;
        padding: 0.5rem 1rem;
        padding-top: 0.6rem;
        font-size: 1.25rem;
        //font-family: 'roboto mono';
        resize: none;
        width: 100%;
        height: 100%;
    }
}
