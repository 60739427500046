.sequence-alignment-container {
    display: flex;
    width: 71vw;
    max-height: 100%;

    .archetype-sequence-whole-container {
        width: calc(60vw - 0.9rem);
        height: 6.5vh;
        overflow-x: scroll;
        overflow-y: hidden;
        border-bottom: 0.2rem solid #e9e9e9;

        &::-webkit-scrollbar {
            display: none;
        }

        &::-webkit-scrollbar-thumb {
            display: none;
        }
    }

    .sequences-names-whole-container {
        max-width: 11vw;
        max-height: calc(42vh - 0.85rem);
        overflow-y: scroll;
        overflow-x: scroll;

        &::-webkit-scrollbar {
            display: none;
        }

        &::-webkit-scrollbar-thumb {
            display: none;
        }
    }

    .sequences-col-container {
        .sequences-whole-container {
            max-width: 60vw;
            max-height: 42vh;
            overflow: scroll;
        }
    }

    .name {
        font-size: 0.9rem;
        padding-right: 0.75rem;
        padding-left: 1rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 11rem;
    }

    .sequence {
        display: flex;
        text-align: center;
        font-family: 'Roboto Mono', sans-serif;
        padding-left: 0.75rem;
        font-weight: normal;
        font-size: 0.9rem;
        white-space: nowrap;
    }

    .sequence-character-container {
        position: relative;
        width: 2ch;
        text-align: center;
        display: flex;
        flex-direction: column;
        margin: 0.165rem;

        .position-number-archetype {
            font-size: 0.5rem;
            font-weight: 500;
            color: #5856e6;
        }

        .position-number-sequence {
            font-size: 0.5rem;
            font-weight: 500;
            color: #eba31e;
        }

        .character-container {
            margin: 0.15rem;
        }

        .mutation-length-hidden {
            position: absolute;
            left: 2.75ch;
            top: 0.5rem;
            padding: 0.075rem;
            display: flex;
            font-weight: bold;
            font-size: 0.5rem;
            border-radius: 50%;
            background: #000000;
            color: #ffffff;
        }
    }

    .hidden {
        visibility: hidden;
    }

    .unknown-mutation {
        .character-container {
            border-radius: 0.15rem;
            background-color: rgb(255, 86, 86);
        }

        &:hover {
            cursor: pointer;
        }
    }

    .known-mutation {
        .character-container {
            border-radius: 0.15rem;
            background-color: rgb(255, 208, 80);
        }

        &:hover {
            cursor: pointer;
        }
    }

    .outlined {
        background-color: rgb(0, 255, 149);
        outline: auto;
    }

    .names-col-row-container {
        display: flex;
        width: 11vw;
        height: 3.75rem;
        align-items: center;
        border-right: 0.2rem solid #e9e9e9;
        font-weight: bold;
    }

    .sequences-col-row-container {
        display: flex;
        height: 3.75rem;
        align-items: center;
    }
}

.archetype-name-col-row-container {
    display: flex;
    border-bottom: 0.2rem solid #e9e9e9;
    border-right: 0.2rem solid #e9e9e9;
    width: 11vw;
    height: 6.5vh;
    align-items: center;
    font-weight: bold;
}

.visability-icon {
    color: #424242;
    margin-right: 0.5rem;
    &:hover {
        cursor: pointer;
    }
}

.up-icon {
    color: #424242;
    margin-right: 0.1rem;
    &:hover {
        cursor: pointer;
    }
}

.down-icon {
    color: #424242;
    margin-right: 0.3rem;
    &:hover {
        cursor: pointer;
    }
}

@media only screen and (max-width: 1300px) {
    .sequence-alignment-container {
        width: 61vw;
        max-height: 100%;

        .archetype-sequence-whole-container {
            width: calc(50vw - 0.9rem);
            height: 6.5vh;
        }

        .sequences-names-whole-container {
            max-width: 15vw;
            max-height: calc(42vh - 0.85rem);
        }

        .sequences-col-container {
            .sequences-whole-container {
                max-width: 50vw;
                max-height: 42vh;
            }
        }

        .name {
            font-size: 0.9rem;
            padding-right: 0.75rem;
            padding-left: 0.5rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 15rem;
        }

        .sequence {
            padding-left: 0.75rem;
            font-weight: normal;
            font-size: 0.9rem;
            white-space: nowrap;
        }

        .sequence-character-container {
            width: 2ch;

            margin: 0.165rem;

            .position-number-archetype {
                font-size: 0.5rem;
            }

            .position-number-sequence {
                font-size: 0.5rem;
            }

            .character-container {
                margin: 0.15rem;
            }

            .mutation-length-hidden {
                left: 2.75ch;
                top: 0.5rem;
                padding: 0.075rem;

                font-size: 0.5rem;
            }
        }

        .unknown-mutation {
            .character-container {
                border-radius: 0.15rem;
            }
        }

        .names-col-row-container {
            width: 15vw;
            height: 3.75rem;
        }

        .sequences-col-row-container {
            height: 3.75rem;
        }
    }

    .archetype-name-col-row-container {
        width: 15vw;
        height: 6.5vh;
    }

    .visability-icon {
        color: #424242;
        margin-right: 0.25rem;
        &:hover {
            cursor: pointer;
        }
    }

    .up-icon {
        color: #424242;
        margin-right: 0.05rem;
        &:hover {
            cursor: pointer;
        }
    }

    .down-icon {
        color: #424242;
        margin-right: 0.15rem;
        &:hover {
            cursor: pointer;
        }
    }
}
