@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;500;700&display=swap');

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
}

::-webkit-scrollbar {
    width: 1rem;
    height: 1rem;
}
::-webkit-scrollbar-button {
    display: none;
}
::-webkit-scrollbar-track {
    background: #ffffff;
}
::-webkit-scrollbar-thumb {
    background: rgb(190, 190, 190);
    border-left: 0.2rem #ffffff solid;
    border-right: 0.2rem #ffffff solid;
    border-top: 0.2rem #ffffff solid;
    border-bottom: 0.2rem #ffffff solid;
}

::-webkit-scrollbar-thumb:hover {
    background: #9b9b9b;
}

.admin-container {
    background: #ffffff;
    font-family: 'roboto';
    margin: 2vw 0;
    width: 45vw;
    margin-left: 7vw;
    //padding-right: 50rem;

    .content-container {
        padding-top: 7.5vh;

        .button-row {
            display: flex;
            flex-basis: 100%;
            justify-content: space-between;
            gap: 5vw;
        }

        h1 {
            padding-top: 2rem;
        }

        .mutations-database-container {
            padding-top: 2.5rem;

            h2 {
                padding-bottom: 1rem;
            }
            .action-title {
                padding-bottom: 0.75rem;
            }

            .action-container {
                padding-top: 2rem;
                //padding-bottom: 2rem;

                .information-container {
                    padding-bottom: 1rem;
                }

                .button-row {
                    width: 8rem;
                    gap: 2rem;
                }

                p {
                    padding-top: 1rem;

                    span {
                        font-weight: bold;
                    }
                }
            }

            .update {
                p {
                    padding-top: 1rem;

                    span {
                        font-weight: bold;
                    }
                }

                .upload-row {
                    height: 1.5rem;
                    padding-bottom: 1rem;
                    padding-top: 0.5rem;

                    .custom-file-upload {
                        border: 0.15rem solid #afafaf;
                        color: #afafaf;
                        padding: 0.4rem 1rem;
                        border-radius: 0.25rem;
                        font-size: 0.85rem;
                        font-weight: 500;
                        cursor: pointer;

                        &:hover {
                            border: 0.15rem solid #000000;
                            color: #000000;
                        }
                    }

                    input[type='file'] {
                        display: none;
                    }
                }

                .changes-list-container {
                    .changes-list {
                        padding-bottom: 1rem;
                    }
                }

                .button-row {
                    width: 17rem;
                    gap: 1rem;
                }
            }
        }
    }
}
