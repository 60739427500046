.information-box-container
{
    display: flex;
    gap: 0.75rem;

    width: 100%;
    padding: 1rem 1.5rem;
    border-radius: 0.25rem;
    
    background: #e0e0e0;
    

    img
    {
        margin: auto 0;
    }

    .text
    {
        margin: auto 0;

        color: #4e4e4e;
        font-weight: bold;
    }
}