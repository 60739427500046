.settings-bar-container {
    display: flex;
    flex-direction: column;
    padding-left: 2.5rem;

    h2 {
        padding-left: 0.5rem;
        font-size: 1.5rem;
    }
}

.output-settings {
    display: flex;
    flex-direction: column;
}

.output-sub-settings {
    margin-left: 2.5rem;
}

.index-range-input-container {
    display: flex;
    gap: 1rem;

    .separator {
        font-size: 1rem;
    }
}

.index-input {
    width: 3.5em;
}

.filter-section-title {
    padding-bottom: 0.5rem;
    padding-top: 2rem;
    font-weight: bold;
    font-size: 1.15rem;
    margin-left: 0.5rem;
}

.filter-section-container {
    margin-left: 1.5rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

@media only screen and (max-width: 1300px) {
    .settings-bar-container {
        padding-left: 0.65rem;

        h2 {
            padding-left: 0.8rem;
            font-size: 1.25rem;
        }
    }

    .output-sub-settings {
        margin-left: 1rem;
    }

    .index-range-input-container {
        display: flex;
        gap: 0.8rem;

        .separator {
            font-size: 1rem;
        }
    }

    .index-input {
        width: 2.5em;
        font-size: 0.8rem;
    }

    .filter-section-title {
        padding-bottom: 0.5rem;
        padding-top: 1.25rem;
        font-weight: bold;
        font-size: 1rem;
        margin-left: 0.8rem;
    }

    .filter-section-container {
        margin-left: 1.25rem;
    }
}
