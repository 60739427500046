.nav-bar {
    display: flex;
    flex-basis: 100%;

    .nav-logo {
        height: 3.25rem;
        margin: auto;

        &:hover {
            cursor: pointer;
        }
    }

    .nav-headers {
        display: flex;
        flex-basis: 100%;
        justify-content: space-between;

        .nav-text {
            display: flex;
            margin: auto;
            gap: 0.5rem;

            &:hover {
                cursor: pointer;
            }

            h2 {
                font-weight: bold;
                font-size: 1.15rem;

                &:hover {
                    text-decoration: underline;
                }
            }

            .drop-down-menu {
                visibility: hidden;
                z-index: 11;
                background: #ffffff;
                padding: 1.5rem;
                border-radius: 0.25rem;
                border: solid 0.15rem #000000;
                position: absolute;
                top: 6rem;

                display: flex;
                flex-direction: column;
                gap: 2rem;

                h3 {
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .open {
                visibility: visible;
            }

            img {
                width: 0.8rem;
            }
        }
    }

    .nav-buttons {
        display: flex;
        gap: 1.5rem;
    }
}
